import FieldContent from '@content';

// Each Field takes 3 arguments:
// 1. Field type (text, url, email, etc, any an `input` type)
// 2. If the field is required (boolean)
// 3. Options object (at least 3 properties: name, label, placeholder)

export default [
  new FieldContent(`text`, true, {
    name: `name`,
    label: `What's your name?`,
    placeholder: `Meryl Streep`,
  }),
  new FieldContent(`email`, true, {
    name: `email`,
    label: `What's your email address?`,
    placeholder: `email@domain.com`,
  }),
  new FieldContent(`tel`, false, {
    name: `phone number`,
    label: `What's your phone number?`,
    placeholder: `111-222-3333`,
  }),
  new FieldContent(`file`, false, {
    name: `resume`,
    label: `Have a resumé we can look at?`,
    placeholder: `Upload PDF`,
  }),
  new FieldContent(`url`, true, {
    name: `portfolio`,
    label: `Have a portfolio? Link it below!`,
    placeholder: `https://yourportfolio.com`,
  }),
  new FieldContent(`url`, false, {
    name: `github`,
    label: `How about a GitHub profile?`,
    placeholder: `https://github.com/username`,
  }),
  new FieldContent(`url`, false, {
    name: `linkedin`,
    label: `And LinkedIn?`,
    placeholder: `https://www.linkedin.com/in/username/`,
  }),
  new FieldContent(`checklist`, false, {
    name: `strengths`,
    label: `In what areas would you consider yourself most proficient? Select all that apply.`,
    items: [
      'Information design',
      'Interaction design',
      'Product design',
      'Aesthetic design',
      'Branding',
      'Engineering: Front-end basics (HTML, CSS)',
      'Engineering: Front-end intermediate (Sass, some JS, CMS experience)',
      'Engineering: Front-end advanced (JS, frameworks such as React/Vue, CSS modules)',
      'Research',
      'Strategy',
      'Communication',
      'Writing',
    ],
  }),
  new FieldContent(`text`, false, {
    name: `availability`,
    label: `When would you be available to start?`,
    placeholder: `Next month? Next week?`,
  }),
  new FieldContent(`file`, false, {
    name: `cover letter`,
    label: `Add a cover letter`,
    placeholder: `Upload PDF`,
  }),
  new FieldContent(`textarea`, false, {
    name: `questions`,
    label: `Anything else you want us to know?`,
    placeholder: `Questions? Concerns?`,
  }),
];

export const positions = [
  {
    name: 'Creative Lead',
    path: '/designers/?position=creative-lead',
    description: 'https://www.upstatement.com/jobs/creative-lead.html',
  },
  {
    name: 'Interactive Designer',
    path: '/designers/?position=interactive-designer',
    description: 'https://www.upstatement.com/jobs/interactive-designer.html',
  },
];
